
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";
import { ElMessage } from "element-plus";

interface Answer {
  id?: number;
  qId?: number;
  text: string;
  isCorrect: boolean;
}

interface Question {
  id?: number;
  text: string;
  answers: Answer[];
}

interface Exercice {
  trimester: string;
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  marked: boolean;
  students: string[];
  classrooms: string[];
  questions: Question[];
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface ClassRoom {
  _id: string;
  name: string;
  subjects: Subject[];
}

interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  schoolarYearsHistory: string[];
}

export default defineComponent({
  name: "add-my-exercise",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const uploadPercentage = ref(0);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const classRooms = ref<ClassRoom[]>([]);

    const store = useStore();
    const router = useRouter();

    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: store.getters.currentUser._id,
      },
    })
      .then(({ data }) => {
        let subjectList: Subject[] = [];
        const classrooms: string[] = [];
        for (const classroom of data) {
          subjectList.push(...classroom.subjects);

          const classRoomSubjects: Subject[] = classroom.subjects;

          //remove duplicated
          subjectList = subjectList.filter(
            (subject, index) =>
              subjectList.findIndex((s) => s._id == subject._id) === index &&
              !modules.value.find((m) =>
                m.subjects.find((ss) => ss._id == subject._id)
              ) &&
              subject.status !== "inactive"
          );

          classRooms.value.push({
            _id: classroom.classRoom._id,
            name: classroom.classRoom.name,
            subjects: classRoomSubjects,
          });
          classrooms.push(classroom.classRoom._id);
        }
        subjects.value = subjectList;
        getStudents(classrooms);
      })
      .catch((e) => console.log(e));

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const exercice = ref<Exercice>({
      trimester,
      subject: "",
      title: "",
      module: "",
      desc: "",
      deadline: "",
      marked: true,
      students: [],
      classrooms: [],
      questions: [{ text: "", answers: [{ text: "", isCorrect: false }] }],
    });

    const spec = ref<string>("0");
    const exerciseType = ref("exercise");

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
      students: Yup.array().test(
        "required-students",
        t("course.requiredStudents"),
        (value) => {
          if (spec.value == "0") return true;
          if (spec.value == "1" && (value?.length == 0 || value == undefined))
            return false;
          else return true;
        }
      ),
      classrooms: Yup.array()
        .min(1, t("course.requiredClassroom"))
        .required(t("course.requiredClassroom")),
    });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);
    const maxSize = ref(0);
    const updateUploadFiles = (file, files) => {
      files.forEach((file) => {
        maxSize.value += file.raw.size;
      });
      maxSize.value = maxSize.value / 1024 / 1024;
      if (maxSize.value > 50) {
        ElMessage.error(t("course.fileSize"));
      }
      uploadFiles.value = files;
    };

    const submit = () => {
      if (exerciseType.value == "quiz") return submitQuiz();
      if (maxSize.value > 50) {
        ElMessage.error(t("course.fileSize"));
        return;
      }

      const data = new FormData();

      if (!exercice.value.deadline) exercice.value.deadline = "";

      if (exercice.value.students.length != 0) {
        exercice.value.students.forEach((st: string) => {
          data.append("students", st);
        });
      }

      for (const name in exercice.value)
        if (name != "students" && name != "classrooms" && name != "questions")
          data.append(name, exercice.value[name]);

      uploadFiles.value.forEach((file) => {
        data.append("resources", file.raw, file.raw.name);
      });

      if (exercice.value.classrooms.length != 0) {
        exercice.value.classrooms.forEach((cr: string) => {
          data.append("classrooms", cr);
        });
      }

      data.append(
        "schoolarYear",
        localStorage.getItem("activeSchoolarYear") as string
      );

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.put(
        "/lms/homework",
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(({ data }) => {
          event("Add exercice", {
            event_category: "Exercice",
            event_label: "Exercice section",
            value: 1,
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.addedExercise"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/exercise/${data._id}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const submitQuiz = () => {
      let test = false;

      exercice.value.questions.map((question: Question) => {
        if (question.text == "") {
          test = true;
          return;
        }
        question.answers.map((answer: Answer) => {
          if (answer.text == "") {
            test = true;
            return;
          }
        });
      });

      if (test)
        return Swal.fire({
          text: t("course.pleaseFillOrDeleteAnswers"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("course.tryAgain"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });

      // eslint-disable-next-line
      const data: any = {};

      if (!exercice.value.deadline) exercice.value.deadline = "";

      if (exercice.value.students.length != 0) {
        const students: string[] = [];
        exercice.value.students.forEach((st: string) => {
          students.push(st);
        });
        data.students = students;
      }

      let questionId = 0;

      exercice.value.questions = exercice.value.questions.map((question) => {
        question.id = questionId++;
        let answerId = 0;
        question.answers = question.answers.map((answer) => {
          answer.qId = question.id;
          answer.id = answerId++;
          return answer;
        });
        return question;
      });

      data.questions = exercice.value.questions;

      for (const name in exercice.value)
        if (
          name != "students" &&
          name != "classrooms" &&
          name != "desc" &&
          name != "questions"
        )
          data[name] = exercice.value[name];

      if (exercice.value.classrooms.length != 0) {
        const classrooms: string[] = [];
        exercice.value.classrooms.forEach((cr: string) => {
          classrooms.push(cr);
        });
        data.classrooms = classrooms;
      }

      data.schoolarYear = localStorage.getItem("activeSchoolarYear") as string;

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.put("/lms/quiz", data as AxiosRequestConfig)
        .then(({ data }) => {
          event("Add quiz", {
            event_category: "Exercice",
            event_label: "Exercice section",
            value: 1,
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.addedExercise"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/quiz/${data._id}/`);
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("course.errorText"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("course.tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const studentList = ref<any[]>([]);

    const sy = window.localStorage.getItem("activeSchoolarYear");

    const getStudents = (classrooms: string[]) => {
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
              schoolarYearsHistory: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data;
        })
        .catch((e) => console.log(e));
    };

    const clearStudents = () => {
      exercice.value.students = [];
    };

    const avaliableClassRooms = computed(() =>
      classRooms.value.filter((c: ClassRoom) =>
        c.subjects.find((s: Subject) => s._id == exercice.value.subject)
      )
    );

    const avaliableStudents = computed(() =>
      studentList.value.filter((s: Student) =>
        exercice.value.classrooms.includes(s.schoolarYearsHistory[sy as string])
      )
    );

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };
    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };
    return {
      avaliableClassRooms,
      avaliableStudents,
      spec,
      clearStudents,
      t,
      classRooms,
      subjects,
      modules,
      upload,
      exercice,
      validationSchema,
      submit,
      submitButtonRef,
      updateUploadFiles,
      exerciseType,
      handleExceed,
      customColorMethod,
      uploadPercentage,
      maxSize,
    };
  },
});
